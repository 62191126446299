import React, { memo } from 'react';
import { List } from '@mui/material';

import HeaderListItem from './HeaderListItem';

interface IProps {
  color?: string;
  bold?: boolean;
  opened?: boolean;
  childItemsById?: any;
  children?: React.ReactNode;
  rootItems: ItemType[];
  onSelect: (id: number) => void;
  onClose: () => void;
}

export type ItemType = {
  id: number;
  title: string;
  parent: number | null;
};

const HeaderList: React.FC<IProps> = (props) => {
  const { childItemsById = {}, rootItems, onSelect, onClose, children, ...rest } = props;

  return (
    <List disablePadding>
      {children}
      {rootItems.map((item) => (
        <HeaderListItem
          key={item.id}
          onSelect={onSelect}
          onClose={onClose}
          item={item}
          childItems={childItemsById}
          {...rest}
        />
      ))}
    </List>
  );
};

export default memo(HeaderList);
