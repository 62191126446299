import * as React from 'react';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from '@emotion/styled';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DatePickerProps } from '@mui/x-date-pickers';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TDate } from '@sinclair/typebox';
import { makeStyles } from 'tss-react/mui';
import { SxProps } from '@mui/system';
import { outputDateToISOString } from 'utils/transform';
import { ClickAwayListener } from '@mui/material';
import DateIconButton from './DateIconButton';
import withDatePicker from './withDatePicker';

const getStringDate = (date: TDate | string) => {
  if (typeof date === 'string' || date instanceof String) return date;
  return date.$d;
};

const lessTDates = (d1: TDate, d2: TDate) => {
  const date1 = new Date(d1.$d);
  date1.setHours(0, 0, 0, 0);
  const date2 = new Date(d2.$d);
  date2.setHours(0, 0, 0, 0);
  return date1 < date2;
};

const isThisDay = (d1: TDate | string, d2: TDate | string) => {
  const date1 = new Date(getStringDate(d1));
  date1.setHours(0, 0, 0, 0);
  const date2 = new Date(getStringDate(d2));
  date2.setHours(0, 0, 0, 0);
  return +date1 === +date2;
};

const isInDates = (start: TDate | string, end: TDate | string, date: TDate) => {
  const startDate = new Date(getStringDate(start));
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(getStringDate(end));
  endDate.setHours(0, 0, 0, 0);
  const dateDate = new Date(date.$d);
  dateDate.setHours(0, 0, 0, 0);
  return startDate <= dateDate && dateDate <= endDate;
};

const useStyles = makeStyles()(() => ({
  sun: {
    color: 'red !important',
  },
  previewDayLeftWrapper: {
    borderTopLeftRadius: '50%  !important',
    borderBottomLeftRadius: '50%  !important',
  },
  previewDayWrapper: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  previewDayRightWrapper: {
    borderTopRightRadius: '50%  !important',
    borderBottomRightRadius: '50%  !important',
  },
  previewDayDefault: {
    border: '1px solid transparent',
  },
  previewDayLeft: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderLeftColor: 'rgba(0, 0, 0, 0.12) !important',
    borderTopLeftRadius: '50%  !important',
    borderBottomLeftRadius: '50%  !important',
  },
  previewDay: {
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    borderRadius: 0,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
  },
  previewDayRight: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderRightColor: 'rgba(0, 0, 0, 0.12)  !important',
    borderTopRightRadius: '50%  !important',
    borderBottomRightRadius: '50%  !important',
  },
  selectedDay: {
    '& .MuiPickersDay-root': {
      borderRadius: '6px',
      backgroundColor: '#4E6DF5',
      color: '#fff',
    },
  },
  selectedRange: {
    '& .MuiPickersDay-root': {
      transform: 'scale(1.1)',
      color: '#383C44',
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
  },
}));

const popperSx: SxProps = {
  '& .MuiPickersCalendarHeader-root, .MuiSvgIcon-root': {
    color: '#4E6DF5',
  },
  '& .MuiPickersCalendarHeader-label': {
    fontSize: 16,
    fontWeight: 600,
  },
  '& .MuiPickersDay-root': {
    fontSize: 15,
    fontWeight: 600,
    color: '#383C44',
    borderRadius: '6px',
    '&.Mui-selected': {
      color: '#383C44',
      backgroundColor: '#fff',
      border: '1px solid rgba(0, 0, 0, 0.38)',
      transform: 'scale(1.1)',
      borderRadius: '6px !important',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
      },
    },
  },
  '& .Mui-selected': {
    color: '#383C44',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.38)',
    transform: 'scale(1.1)',
    borderRadius: '6px !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
  },
};

const DateRangePickerStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const DateRangePicker = (props: DateRangePickerProps) => {
  const { value, onChange, onClose, ...rest } = props;
  const [startValue, setStartValue] = useState(value && value.length ? value[0] : null);
  const [endValue, setEndValue] = useState(value && value.length ? value[1] : null);
  const [startDate, setStartDate] = React.useState<TDate | null>(null);
  const [endDate, setEndDate] = React.useState<TDate | null>(null);
  const [previewDate, setPreviewDate] = React.useState<TDate | null>(null);
  const [datesPicked, setDatesPicked] = React.useState(0);
  const [pickerYear, setPickerYear] = useState(new Date().getFullYear());
  const { classes } = useStyles();

  const handleOnChange = (date: TDate) => {
    setStartValue(null);
    setEndValue(null);
    if (pickerYear !== date.$y) {
      setPickerYear(date.$y);
      return;
    }
    setDatesPicked(datesPicked + 1);
    if (datesPicked % 2 !== 0) {
      setPreviewDate(null);
      if (lessTDates(date, startDate)) {
        setEndDate(startDate);
        setStartDate(date);
        onChange([date.$d, startDate.$d]);
        onClose();
      } else {
        setEndDate(date);
        onChange([startDate.$d, date.$d]);
        onClose();
      }
    } else {
      setStartDate(date);
      onChange([date.$d, null]);
      setEndDate(null);
    }
  };

  const handleOnHover = (day: TDate) => {
    setPreviewDate(day);
  };

  const handleRenderDay = (day: TDate, _value: TDate[], DayComponentProps: PickersDayProps<TDate>) => {
    const dayDate = new Date(day.$d);
    const prevDayStr = DayComponentProps.key as string;
    const start = startValue || startDate;
    const end = endValue || endDate;
    let wrapperClassName = '';
    let className = '';

    if ((start && isThisDay(start, day as TDate)) || (end && isThisDay(end, day as TDate))) {
      wrapperClassName += ` ${classes.selectedDay}`;
    } else if (!DayComponentProps.outsideCurrentMonth && start && end && isInDates(start, end, day as TDate)) {
      wrapperClassName += ` ${classes.selectedRange}`;
      if (prevDayStr.startsWith('Fri')) className += ` ${classes.previewDayRightWrapper}`;
      if (prevDayStr.startsWith('Sat')) className += ` ${classes.previewDayLeftWrapper}`;
    } else if (
      !DayComponentProps.outsideCurrentMonth &&
      start &&
      !end &&
      previewDate &&
      isInDates(start, previewDate, day as TDate)
    ) {
      wrapperClassName += ` ${classes.previewDayWrapper}`;
      className += ` ${classes.previewDay}`;
      if (isThisDay(day as TDate, previewDate) || prevDayStr.startsWith('Fri')) {
        wrapperClassName += ` ${classes.previewDayRightWrapper}`;
        className += ` ${classes.previewDayRight}`;
      }
      if (prevDayStr.startsWith('Sat')) {
        wrapperClassName += ` ${classes.previewDayLeftWrapper}`;
        className += ` ${classes.previewDayLeft}`;
      }
    } else if (
      !DayComponentProps.outsideCurrentMonth &&
      start &&
      !end &&
      previewDate &&
      isInDates(previewDate, start, day as TDate)
    ) {
      wrapperClassName += ` ${classes.previewDayWrapper}`;
      className += ` ${classes.previewDay}`;
      if (isThisDay(day as TDate, previewDate) || prevDayStr.startsWith('Sat')) {
        wrapperClassName += ` ${classes.previewDayLeftWrapper}`;
        className += ` ${classes.previewDayLeft}`;
      }
      if (prevDayStr.startsWith('Fri')) {
        wrapperClassName += ` ${classes.previewDayRightWrapper}`;
        className += ` ${classes.previewDayRight}`;
      }
    }

    return (
      <div
        className={wrapperClassName}
        key={day.toString() + className}
        onMouseEnter={() => handleOnHover(day as TDate)}
      >
        <PickersDay
          className={`${classes.previewDayDefault} ${!dayDate.getDay() && classes.sun} ${className}`}
          {...DayComponentProps}
        />
      </div>
    );
  };

  return (
    <DateRangePickerStyled>
      <DatePicker
        {...rest}
        value={null}
        PopperProps={{
          sx: popperSx,
        }}
        onChange={(date) => handleOnChange(date as TDate)}
        closeOnSelect={false}
        renderDay={(day, _value, DayComponentProps) =>
          handleRenderDay(day as TDate, _value as TDate[], DayComponentProps as PickersDayProps<TDate>)
        }
      />
    </DateRangePickerStyled>
  );
};

interface IProps {
  ref?: any;
  timeZone?: string;
  value?: [string, string];
  label?: string;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  open: boolean;
  fullWidth?: boolean;
  disableFuture?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onChange?: (v: string[]) => void;
  InputComponent?: React.ElementType;
  calendars?: 1 | 2 | 3;
  minDate?: Date;
  maxDate?: Date;
}

const MuiDateRangePicker: React.FC<IProps> = (props) => {
  const {
    label,
    placeholder,
    value,
    timeZone = 'UTC',
    onOpen,
    onChange,
    open,
    onClose,
    error,
    helperText,
    fullWidth,
    InputComponent = DateIconButton,
    ...rest
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = ([start, end]: [start: any, end: any]) => {
    const range = end && !start ? [end, start] : [start, end];
    onChange(range.map((v) => outputDateToISOString(v, timeZone)));
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        <DateRangePicker
          {...rest}
          open={open}
          value={value}
          onChange={(newValue) => {
            handleChange(newValue as [string, string]);
          }}
          PopperProps={{
            placement: 'bottom-end',
            anchorEl,
          }}
          onOpen={onOpen}
          onClose={onClose}
          renderInput={(props) => {
            const { inputRef, ...rest } = props;
            return (
              <InputComponent
                {...rest}
                ref={inputRef}
                value={value}
                fullWidth={fullWidth}
                label={label}
                placeholder={placeholder}
                helperText={helperText}
                error={error}
                onClick={handleClick}
                onOpen={onOpen}
              />
            );
          }}
        />
      </div>
    </ClickAwayListener>
  );
};

interface DateRangePickerProps extends Omit<DatePickerProps<unknown, unknown>, 'value'> {
  maxDate?: any;
  minDate?: any;
  value: [string, string];
  onChange: (value: any) => void;
}

export default withDatePicker(MuiDateRangePicker);
