import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InputLimit from './InputLimit';

type Props = TextFieldProps & {
  value?: string;
  maxLength: number;
  helperText?: string;
  withLimitHelper?: boolean;
};

const useFormHelperTextStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0.5, 0, 0, 2),
    '&:last-child': {
      flexShrink: 0,
    },
  },
}));

const LimitedTextField: React.FC<Props> = (props) => {
  const { value, maxLength, inputProps, FormHelperTextProps, helperText, withLimitHelper = true, ...rest } = props;
  const { classes: formHelperTextClasses } = useFormHelperTextStyles(undefined, {
    props: FormHelperTextProps,
  });
  return (
    <TextField
      {...rest}
      value={value}
      inputProps={{ ...inputProps, maxLength }}
      FormHelperTextProps={{ ...FormHelperTextProps, classes: formHelperTextClasses }}
      helperText={
        <>
          <span>{helperText}</span>
          {withLimitHelper && <InputLimit value={value} maxLength={maxLength} />}
        </>
      }
    />
  );
};

export default LimitedTextField;
