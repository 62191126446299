import React, { memo } from 'react';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import nodeMap from 'utils/nodeMap';

import MobileMenu from './MobileMenu';
import Logo from './Logo';
import Header from './Header';

interface HeaderProps {
  right: React.ReactNode;
  avatar: React.ReactNode;
  left?: React.ReactNode;
  search?: React.ReactNode;
}

const mapComponents = (child: React.ReactNode) => child && <Grid item>{child}</Grid>;

const getCenter = (left: React.ReactNode, right: React.ReactNode) => (
  <Grid container justifyContent="space-between" alignItems="center">
    <Grid item>
      <Grid container spacing={3} alignItems="center">
        {nodeMap(left, mapComponents)}
      </Grid>
    </Grid>
    <Grid item>
      <Grid container spacing={3} alignItems="center">
        {nodeMap(right, mapComponents)}
      </Grid>
    </Grid>
  </Grid>
);

const leftComponent = (isMobile: boolean, left: React.ReactNode, right: React.ReactNode, search: React.ReactNode) => {
  if (isMobile && (left || right || search)) {
    return (
      <Grid container alignItems="center">
        <Grid item>
          <MobileMenu>
            {left}
            {right}
          </MobileMenu>
        </Grid>
        <Grid item style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
          {search}
        </Grid>
      </Grid>
    );
  }
  if (isMobile) {
    return <div style={{ width: 52, height: 32 }} />;
  }
  return (
    <Grid container>
      <Grid item style={{ marginRight: 28 }}>
        <Logo />
      </Grid>
      {left && <Grid item>{left}</Grid>}
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        {search}
      </Grid>
    </Grid>
  );
};

const HeaderContainer: React.FC<HeaderProps> = (props) => {
  const { left, right, avatar, search } = props;
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  return (
    <Header
      left={leftComponent(isMobile, left, right, search)}
      navigation={(isMobile && <Logo />) || getCenter(left, right)}
      avatar={avatar}
    />
  );
};

export default memo(HeaderContainer);
