import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'i18n';
import { AuthSelectors } from 'store/selectors';

import MenuItem from 'components/common/MenuItem';
import { makeStyles } from 'tss-react/mui';
import SwitchListLanguage from './SwitchListLanguage';
import ToggleSwitchLanguages from './ToggleSwitchLanguages';

interface SwitchLanguageHeaderItemProps {
  menuItem?: boolean;
}

const useStyles = makeStyles()(() => ({
  root: {
    textTransform: 'uppercase',
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    },
  },
}));

const SwitchLanguage: React.FC<SwitchLanguageHeaderItemProps> = (props) => {
  const { classes } = useStyles();
  const { menuItem } = props;
  const { i18n } = useTranslation();
  const preferredLanguage = useSelector(AuthSelectors.preferredLanguage);

  const { allLanguages } = i18n.options as any;

  const handleToggle = (nextLanguage: string) => {
    if (nextLanguage === i18n.language) return;
    const { languageDetector } = i18n.services;
    languageDetector.detectors.cookie.cacheUserLanguage(nextLanguage, {
      ...languageDetector.options,
      ...(preferredLanguage &&
        preferredLanguage !== nextLanguage && {
          cookieMinutes: 0,
        }),
    });

    if (nextLanguage === 'en') {
      let currentRoute = window.location.href.replace(/\/fr(\/|$)/, '/');
      // If fr is last path component
      currentRoute = currentRoute.replace(/\/fr(\?|$)/, '/?');
      window.location.replace(currentRoute);
    } else {
      window.location.reload();
    }
  };

  if (menuItem) {
    return (
      <MenuItem className={classes.root}>
        <ToggleSwitchLanguages onClick={handleToggle} languages={allLanguages.sort()} currentLanguage={i18n.language} />
      </MenuItem>
    );
  }

  return <SwitchListLanguage onClick={handleToggle} languages={allLanguages.sort()} currentLanguage={i18n.language} />;
};

export default SwitchLanguage;
