import React, { ChangeEvent } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from 'tss-react/mui';
import { ButtonBase, Popover, TextField } from '@mui/material';
import { useTranslation } from 'i18n';

import ExpandIconCheckbox from 'components/common/ExpandIconCheckbox';

interface IProps {
  title: string | React.ReactNode;
  onSearch: (v: string) => void;
  children: (f: () => void) => React.ReactNode;
  inputEl: React.Ref<any>;
}

// TODO: check this
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 380,
    background: 'white',
    padding: theme.spacing(2, 1),
    maxHeight: '60%',
    ...theme.mixins.scrollBarOverflowYAuto,
  },
  input: {
    padding: theme.spacing(0, 2, 1),
  },
  label: {
    [theme.breakpoints.down('md')]: {
      width: 179,
    },

    textAlign: 'left',
    paddingLeft: 8,
    ...theme.typography.body2,
    backgroundColor: 'unset',
    border: 'none',
    textTransform: 'uppercase',
    color: theme.palette.custom.green.main,
    fontWeight: 600,
    '& svg': {
      color: theme.palette.custom.green.main,
    },
  },
}));

const HeaderSelect: React.FC<IProps> = (props) => {
  const { title, onSearch, children, inputEl } = props;
  const [t] = useTranslation('admin');
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onSearch('');
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <>
      <ButtonBase
        disableRipple
        onClick={handleOpen}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        className={classes.label}
        disabled={open}
        ref={inputEl}
      >
        <span style={{ width: '100%' }}>{title}</span>
        <ExpandIconCheckbox style={{ marginRight: -8 }} checked={open} IconComponent={ArrowDropDownIcon} />
      </ButtonBase>
      <Popover
        PaperProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          className: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -20,
          horizontal: 'right',
        }}
      >
        <TextField
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: '#7B8BAD',
              },
            },
          }}
          fullWidth
          autoFocus
          onChange={handleChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          className={classes.input}
          placeholder={t('adminHeader.placeholder')}
        />
        {children(handleClose)}
      </Popover>
    </>
  );
};

export default HeaderSelect;
