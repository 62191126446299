import React from 'react';
import { makeStyles } from 'tss-react/mui';
import StickyBottom, { JustifyContent, Position } from './StickyBottom';

interface IProps {
  children: React.ReactNode;
  position?: Position;
  justifyContent?: JustifyContent;
  className?: any;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(3, 8),
    backgroundColor: theme.palette.custom.white,
    color: theme.palette.custom.white,
    borderTop: `1px solid ${theme.palette.custom.border.gray}`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 8),
      boxShadow: '0px -8px 16px rgba(60, 85, 165, 0.16)',
    },
  },
}));

const StickyBottomContent: React.FC<IProps> = (props) => {
  const { children, className, ...rest } = props;
  const { classes, cx } = useStyles();
  return (
    <StickyBottom className={cx(classes.container, className)} {...rest}>
      {children}
    </StickyBottom>
  );
};

export default StickyBottomContent;
