import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthProps } from 'hooks';
import useModal from 'hooks/useModal';
import AuthModal from 'components/auth/AuthModal';
import { AuthSelectors } from 'store/selectors';
import { AuthActions } from 'store/actions';
import NudgeToLoginPopover from './NudgeToLoginPopover';

const withNudgeToLoginPopover = <P,>(Component: React.ComponentType<P>) => {
  const NudgeToLoginPopoverWrapper: React.FC<P> = (props) => {
    const [showAuthModal] = useModal(AuthModal);
    const [open, setOpen] = useState(false);
    const { isAuthenticated } = useAuthProps();
    const dispatch = useDispatch();

    const linkState = useSelector(AuthSelectors.socialLinksState);

    useEffect(() => {
      if (!linkState.result) {
        dispatch(AuthActions.socialLinks.reset());
        dispatch(AuthActions.socialLinks.request());
      }
    }, [dispatch, linkState?.result]);

    useEffect(() => {
      if (!sessionStorage.getItem('shownNudgeToLoginPopover') && !isAuthenticated) {
        sessionStorage.setItem('shownNudgeToLoginPopover', String(true));
        setOpen(true);
      } else if (isAuthenticated) setOpen(false);
    }, [isAuthenticated]);

    const onGoogleButtonClick = () => {
      const authWindow = window.open(
        linkState?.result?.auth_url,
        'mozillaWindow',
        'popup,left=100,top=100,width=640,height=640',
      );
      const interval = setInterval(() => {
        try {
          if (authWindow.location.href.includes(window.location.origin)) {
            authWindow.close();
            let redirectURL = authWindow.location.href;
            if (redirectURL.includes('&isRedirect')) {
              redirectURL = redirectURL.replace('&isRedirect', '');
            }
            window.location.replace(`${redirectURL}&redirect_to=${window.location.pathname}${window.location.search}`);
            clearInterval(interval);
          }
        } catch (error) {
          console.log(error);
          if (!authWindow || authWindow?.closed) {
            clearInterval(interval);
          }
        }
      }, 250);
    };

    return (
      <>
        <Component {...props} />
        {open && (
          <NudgeToLoginPopover
            getStarted={() => showAuthModal({ variant: 'createAccount' })}
            loginEmail={() => showAuthModal({ variant: 'login' })}
            loginGoogle={onGoogleButtonClick}
            onClose={() => setOpen(false)}
          />
        )}
      </>
    );
  };
  return NudgeToLoginPopoverWrapper;
};

export default withNudgeToLoginPopover;
