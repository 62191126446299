import React from 'react';
import { Divider, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

const HeaderDivider: React.FC = () => {
  const { classes } = useStyles();

  return <Divider className={classes.divider} />;
};

export default HeaderDivider;
