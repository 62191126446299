import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Dialog, DialogProps, Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'i18n';

import { routes } from 'utils/routing';

import { EventDraftActions } from 'store/actions';
import { EventDraftSelectors } from 'store/selectors';

import CustomDialogTitle from 'components/common/CustomDialogTitle';
import CreateEventModalForm from './CreateEventModalForm';
import { RootState } from '../../store/rootReducer';

interface CreateEventModalContainerProps extends DialogProps {
  onSubmit: (values: any) => void;
}

const CreateEventModalContainer: React.FC<CreateEventModalContainerProps> = (props) => {
  const { onClose, ...other } = props;
  const dispatch = useDispatch();
  const router = useRouter();
  const [t] = useTranslation('admin');

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const createEventDraftState = useSelector((state: RootState) => EventDraftSelectors.createEventDraft(state));
  const { result, isPending, isFulfilled } = createEventDraftState;

  const organizationId = Number(router.query.organizationId);

  const handleClose = (e: React.MouseEvent) => {
    onClose(e, null);
  };

  const handleSubmit = useCallback(
    (values: any) => {
      dispatch(EventDraftActions.createEvent.request({ params: { organizationId, entity: values } }));
    },
    [dispatch, organizationId],
  );

  useEffect(() => {
    if (result && isFulfilled) {
      const route = routes.adminEditEvent(organizationId, result.id);
      router.push(route.href);
      dispatch(EventDraftActions.createEvent.reset());
      onClose({}, null);
    }
  }, [dispatch, organizationId, router, result, isFulfilled, onClose]);

  return (
    <Dialog fullWidth fullScreen={isMobile} {...other} onClose={onClose}>
      <CustomDialogTitle onClose={handleClose}>{t('creatingAdminEvent.common.createNewEvent')}</CustomDialogTitle>
      <CreateEventModalForm
        isSending={isPending}
        disabled={isPending || isFulfilled}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default CreateEventModalContainer;
