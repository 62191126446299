import React, { memo, useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Collapse, ListItem, ListItemText, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import ExpandIconCheckbox from 'components/common/ExpandIconCheckbox';
import HeaderList, { ItemType } from './HeaderList';

interface IProps {
  color?: string;
  childItems: any;
  bold?: boolean;
  opened?: boolean;
  item: ItemType;
  onSelect: (id: number) => void;
  onClose: () => void;
}

interface StyleProps {
  color?: string;
}

const useStyles = makeStyles<StyleProps, 'collapse'>()((theme, props, classes) => ({
  collapse: {},
  text: {
    fontWeight: theme.typography.fontWeightMediumBold,
  },
  list: {
    '&:hover': {
      color: props?.color || 'unset',
      borderRadius: 8,
      backgroundColor: props?.color === '#5FC6B9' ? 'rgba(95, 198, 185, 0.2)' : 'unset',
    },
    [`.${classes.collapse} &`]: {
      paddingLeft: theme.spacing(4),
    },
    [`.${classes.collapse} .${classes.collapse} &`]: {
      paddingLeft: theme.spacing(6),
    },
    [`.${classes.collapse} .${classes.collapse} .${classes.collapse} &`]: {
      paddingLeft: theme.spacing(8),
    },
    [`.${classes.collapse} .${classes.collapse} .${classes.collapse} .${classes.collapse} &`]: {
      paddingLeft: theme.spacing(10),
    },
  },
}));

const HeaderListItem: React.FC<IProps> = (props) => {
  const { color, childItems, item, onSelect, onClose, bold, opened = false } = props;
  const { classes, cx } = useStyles({ color });
  const [open, setOpen] = useState(opened);
  const hasChildren = Array.isArray(childItems[item.id]) && childItems[item.id].length > 0;
  const handleClick: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(opened);
  }, [opened]);

  const handleSelect = () => {
    onSelect(item.id);
    onClose();
  };

  return (
    <>
      <ListItem button onClick={handleSelect} className={classes.list}>
        <ListItemText disableTypography className={cx({ [classes.text]: bold })}>
          <Typography style={{ fontWeight: 'inherit' }} noWrap variant="body1">
            {item.title}
          </Typography>
        </ListItemText>
        {hasChildren && (
          <ExpandIconCheckbox
            arrowColor={color}
            checked={open}
            IconComponent={ArrowDropDownIcon}
            onClick={handleClick}
          />
        )}
      </ListItem>
      {hasChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit className={classes.collapse}>
          <HeaderList
            color={color}
            onSelect={onSelect}
            onClose={onClose}
            childItemsById={childItems}
            rootItems={childItems[item.id]}
            opened={opened}
          />
        </Collapse>
      )}
    </>
  );
};

export default memo(HeaderListItem);
