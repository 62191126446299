import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSelectors } from 'store/selectors';
import { UserActions } from 'store/actions';
import dynamic from 'next/dynamic';
import useModal from 'hooks/useModal';
import WelcomePopover from './WelcomePopover';
import { QUESTION_SLUG } from '../types/question';
import SuccessfullyCreatedAccountPopover from './SuccessfullyCreatedAccountPopover';

const UserProfileQuestionsModal = dynamic(
  () => import('components/UserProfileQuestionsPopovers/UserProfileQuestionsModal'),
  { ssr: false },
);

const EMAILS_TEST = ['jliveadmindev@we-tribu.com', 'jliveadminstage@we-tribu.com', 'jliveadminprod@we-tribu.com'];
const QUESTIONS_TEST = [
  QUESTION_SLUG.GENDER,
  QUESTION_SLUG.BIRTH_DATE,
  QUESTION_SLUG.RELATIONSHIP_STATUS,
  QUESTION_SLUG.ADDRESS,
  QUESTION_SLUG.PHONE,
  QUESTION_SLUG.RELIGION,
  QUESTION_SLUG.CHILDREN_IN_HOUSEHOLD,
  QUESTION_SLUG.JEWISH_DESCENDANTS,
  QUESTION_SLUG.LANGUAGES_SPOKEN,
  QUESTION_SLUG.MAIDEN_NAME,
  QUESTION_SLUG.INTERESTS,
];

const getQuestions = (user: any) => {
  let questions: string[] = [];
  if (!user) return questions;
  if (!user.gender) questions.push(QUESTION_SLUG.GENDER);
  if (!user.birth_date) questions.push(QUESTION_SLUG.BIRTH_DATE);
  if (!user.relationship_status) questions.push(QUESTION_SLUG.RELATIONSHIP_STATUS);
  if (!user.address.address1 || !user.address.city || !user.address.country || !user.address.postal_code)
    questions.push(QUESTION_SLUG.ADDRESS);
  if (!user.phone) questions.push(QUESTION_SLUG.PHONE);
  if (!user.religion) questions.push(QUESTION_SLUG.RELIGION);
  if (
    !user.children_in_household &&
    user.children_in_household !== '0' &&
    (user.relationship_status === 'married' || user.relationship_status === 'divorced')
  )
    questions.push(QUESTION_SLUG.CHILDREN_IN_HOUSEHOLD);
  if (!user.jewish_descendants.length) questions.push(QUESTION_SLUG.JEWISH_DESCENDANTS);
  if (!user.languages_spoken.length) questions.push(QUESTION_SLUG.LANGUAGES_SPOKEN);
  if (!user.maiden_name && user.gender === 'f' && user.relationship_status === 'married')
    questions.push(QUESTION_SLUG.MAIDEN_NAME);
  if (!user.interests.length || user.interests.length < 5) {
    questions = questions.slice(0, 4);
    questions.push(QUESTION_SLUG.INTERESTS);
  }

  return questions.slice(0, 5);
};

const withUserProfileQuestionsPopover = <P,>(Component: React.ComponentType<P>) => {
  const UserProfileQuestionsPopoverWrapper: React.FC<P> = (props) => {
    const dispatch = useDispatch();
    const [showProfileQuestionsModal] = useModal(UserProfileQuestionsModal);
    const [shown, setShown] = useState(false);
    const currentUser = useSelector(AuthSelectors.currentUser);
    const questions = useMemo(() => {
      if (EMAILS_TEST.includes(currentUser?.email)) return QUESTIONS_TEST;
      return currentUser ? getQuestions(currentUser) : [];
    }, [currentUser]);
    const [open, setOpen] = useState(EMAILS_TEST.includes(currentUser?.email));
    const [openOnSuccessfullyCreatedAccount, setOpenOnSuccessfullyCreatedAccount] = useState(false);
    const { isFulfilled, error } = useSelector(AuthSelectors.setPasswordState);
    const isSuccessfullySubmittedSettingPassword = isFulfilled && !error;
    const [now] = useState(new Date());
    const twentyFourHours = 1000 * 60 * 60 * 24;
    const twoWeeks = twentyFourHours * 14;

    const tosAccepted = useMemo(
      () =>
        currentUser?.terms_and_conditions_accepted_date && new Date(currentUser?.terms_and_conditions_accepted_date),
      [currentUser?.terms_and_conditions_accepted_date],
    );

    const updated = useMemo(
      () => currentUser?.helper_popup_counter_updated_at && new Date(currentUser?.helper_popup_counter_updated_at),
      [currentUser?.helper_popup_counter_updated_at],
    );

    // const joined = useMemo(
    //   () => currentUser?.date_joined && new Date(currentUser?.date_joined),
    //   [currentUser?.date_joined],
    // );

    useEffect(() => {
      if (isSuccessfullySubmittedSettingPassword) setOpenOnSuccessfullyCreatedAccount(true);
    }, [isSuccessfullySubmittedSettingPassword, setOpenOnSuccessfullyCreatedAccount]);

    const handleOpenModal = () => showProfileQuestionsModal({ questions });

    const updateCounter = useCallback(
      () =>
        dispatch(
          UserActions.partialUpdateUser.request({
            id: currentUser?.id,
            entity: { helper_popup_counter: currentUser.helper_popup_counter + 1 },
          }),
        ),
      [dispatch, currentUser],
    );

    useEffect(() => {
      if (
        !shown &&
        !open &&
        currentUser &&
        !EMAILS_TEST.includes(currentUser?.email) &&
        currentUser?.is_terms_and_conditions_accepted &&
        questions.length &&
        currentUser?.helper_popup_counter < 2 &&
        tosAccepted &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        now - tosAccepted > twentyFourHours &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (!updated || now - updated >= twoWeeks)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // (!joined || now - joined >= twoWeeks)
      ) {
        setShown(true);
        setOpen(true);
        updateCounter();
      }
    }, [currentUser, now, open, questions, shown, tosAccepted, twentyFourHours, twoWeeks, updateCounter, updated]);

    return (
      <>
        <Component {...props} />
        {open && <WelcomePopover onOpenModal={handleOpenModal} onClose={() => setOpen(false)} />}
        {openOnSuccessfullyCreatedAccount && (
          <SuccessfullyCreatedAccountPopover onClose={() => setOpenOnSuccessfullyCreatedAccount(false)} />
        )}
      </>
    );
  };
  return UserProfileQuestionsPopoverWrapper;
};

export default withUserProfileQuestionsPopover;
