import React from 'react';
import { useTranslation } from 'i18n';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface SwitchListLanguageProps {
  onClick?: (lng: string) => void;
  languages: string[];
  currentLanguage: string;
}

const useStyles = makeStyles()(() => ({
  menuTitle: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '160%',
    color: 'rgba(78, 109, 245, 0.5)',
    // textTransform: 'none',
    '&:hover': {
      color: 'white',
    },
  },
  selectedMenuTitle: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '160%',
    color: '#4E6DF5',
    // textTransform: 'none',
    '&:hover': {
      color: 'white',
    },
  },
  toggleButtonSelected: {
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#4E6DF5',
      },
    },
  },
}));

const ToggleSwitchLanguages: React.FC<SwitchListLanguageProps> = (props) => {
  const { onClick, languages, currentLanguage } = props;
  const [t] = useTranslation();
  const { classes } = useStyles();

  const handleClick = (event: React.MouseEvent, nextValue: string) => {
    onClick(nextValue);
  };

  return (
    <ToggleButtonGroup color="primary" size="small" exclusive value={currentLanguage} onChange={handleClick}>
      {languages.map((lng: string) => (
        <ToggleButton
          className={classes.toggleButtonSelected}
          key={lng}
          value={lng}
          style={{
            outlineColor: lng === currentLanguage ? '#4E6DF5' : 'transparent',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            margin: '2px',
          }}
        >
          <Typography
            textTransform="uppercase"
            className={lng === currentLanguage ? classes.selectedMenuTitle : classes.menuTitle}
          >
            {t('common:general.language', { context: lng })}
          </Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleSwitchLanguages;
