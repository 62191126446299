import React, { forwardRef } from 'react';
import { Link as MuiLink, LinkProps } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { makeStyles } from 'tss-react/mui';
import { LinkTypeMap } from '@mui/material/Link/Link';

interface IProps {
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const useStyles = makeStyles<void, 'startIcon' | 'endIcon'>()((theme, _params, classes) => ({
  root: {
    [`&.${classes.startIcon}, &.${classes.endIcon}`]: {
      display: 'flex',
      alignItems: 'center',
    },
    [`&.${classes.startIcon} > *:nth-child(1)`]: {
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(1),
      },
    },
    [`&.${classes.endIcon} > *:nth-child(2)`]: {
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(1),
      },
    },
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
    color: theme.palette.action.disabled,
  },
  endIcon: {},
  startIcon: {},
}));

const Link = forwardRef<any, IProps & LinkProps>((props, ref) => {
  const { disabled, className, startIcon, endIcon, children, ...rest } = props;
  const { classes, cx } = useStyles();
  return (
    <MuiLink
      ref={ref}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      className={cx(className, classes.root, {
        [classes.disabled]: disabled,
        [classes.startIcon]: startIcon,
        [classes.endIcon]: endIcon,
      })}
      {...rest}
    >
      {startIcon}
      {children}
      {endIcon}
    </MuiLink>
  );
});

export default Link as OverridableComponent<LinkTypeMap<IProps>>;
