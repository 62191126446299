import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { routes } from 'utils/routing';
import Image from 'components/common/Image';
import { Link } from '@mui/material';

interface IProps {
  variant?: 'dark' | 'light' | 'color';
}

const useStyles = makeStyles()(() => ({
  logo: {
    minWidth: '68px',
    maxHeight: '34px',
    marginBottom: 'auto',
  },
  link: {
    display: 'inline-block',
    color: 'unset',
    textDecoration: 'none',
  },
}));

const Logo: React.FC<IProps> = ({ variant = 'color' }) => {
  const { classes } = useStyles();
  return (
    <Link href={routes.home().link} className={classes.link}>
      <Image
        variant="aspectRatio"
        objectFit="contain"
        className={classes.logo}
        src={`/static/svg/jlive-logo/logo-${variant}.svg`}
        alt={`logo-${variant}`}
      />
    </Link>
  );
};

export default Logo;
