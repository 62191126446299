import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Avatar, ButtonBase, Divider, Grid, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SwitchLanguage from './SwitchLanguage';
import { HeaderMenu } from './HeaderItems';

interface HeaderAvatarProps {
  children: (handleClose: () => void) => React.ReactNode;
  currentUser: any;
}

const useStyles = makeStyles()((theme) => ({
  avatar: {
    color: theme.palette.common.white,
    background: theme.palette.secondary.main,
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

const HeaderAvatar: React.FC<HeaderAvatarProps> = (props) => {
  const { children, currentUser } = props;
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid container alignItems="center" wrap="nowrap" onClick={handleOpenClick}>
        <Grid item>
          <Avatar
            className={classes.avatar}
            variant="circular"
            component={ButtonBase}
            src={currentUser?.image?.original_url}
          />
        </Grid>
        <Grid item>
          <IconButton size="small">
            <ArrowDropDownIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
      <HeaderMenu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        <SwitchLanguage menuItem />
        <Divider className={classes.divider} />
        {children(handleClose)}
      </HeaderMenu>
    </>
  );
};

export default HeaderAvatar;
