import React from 'react';
import { useTranslation } from 'i18n';

import MenuItem from 'components/common/MenuItem';
import { makeStyles } from 'tss-react/mui';
import { Link } from '@mui/material';
import HeaderLinkItem from './HeaderLinkItem';

interface HeaderNavigationProps {
  menuItem: boolean;
  link: any;
  tKey: string;
  target?: string;
}

const useStyles = makeStyles()({
  menuItem: {
    width: '100%',
    fontSize: 13,
    fontWeight: 600,
  },
});

const HeaderNavigation: React.FC<HeaderNavigationProps> = (props) => {
  const { menuItem, target, link, tKey } = props;
  const [t] = useTranslation();
  const { classes } = useStyles();

  return menuItem ? (
    <MenuItem>
      <Link
        className={classes.menuItem}
        href={link}
        target={target}
        variant="body2"
        underline="none"
        color="textPrimary"
      >
        {t(tKey).toUpperCase()}
      </Link>
    </MenuItem>
  ) : (
    <HeaderLinkItem label={t(tKey)} href={link} target={target} />
  );
};

export default HeaderNavigation;
