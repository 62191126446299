import React from 'react';
import { MenuItem, Theme, Typography } from '@mui/material';
import { useTranslation } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import SwitchLanguageList from './SwitchLanguageList';

interface SwitchListLanguageProps {
  onClick?: (lng: string) => void;
  languages: string[];
  currentLanguage: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  menuTitle: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '160%',
    color: theme.palette.text.primary,
  },
  selectedMenuTitle: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '160%',
    color: '#4E6DF5',
  },
}));

const SwitchListLanguage: React.FC<SwitchListLanguageProps> = (props) => {
  const { onClick, languages, currentLanguage } = props;
  const [t] = useTranslation();
  const { classes } = useStyles();

  return (
    <SwitchLanguageList title={t('common:general.language', { context: currentLanguage })}>
      {languages.map((lng: string) => (
        <MenuItem key={lng} onClick={() => onClick(lng)}>
          <Typography
            textTransform="uppercase"
            className={currentLanguage === lng ? classes.selectedMenuTitle : classes.menuTitle}
          >
            {t('common:general.language', { context: lng })}
          </Typography>
        </MenuItem>
      ))}
    </SwitchLanguageList>
  );
};

export default SwitchListLanguage;
