import React, { useEffect } from 'react';

import { AuthSelectors, DetectMarketSelectors, MarketsSelectors, UserSelectors } from 'store/selectors';

import { useDispatch, useSelector } from 'react-redux';
import { makeEntitiesLoader } from 'hooks/makeSimpleLoader';
import { DetectMarketActions, MarketsActions } from 'store/actions';
import { useQueryParams } from 'hooks';
import { RootState } from 'store/rootReducer';
import SwitchListMarket from './SwitchListMarket';

const useMarketsState = makeEntitiesLoader(MarketsActions.getMarkets, MarketsSelectors.marketsState);
const params = {
  page_size: 100,
};

const SwitchMarket: React.FC = () => {
  const marketsState = useMarketsState(() => params, []);
  const market = useSelector(DetectMarketSelectors.getSelectedMarket);
  const dispatch = useDispatch();
  const authUser = useSelector(AuthSelectors.sessionUser);
  const sessionUser = useSelector((state: RootState) => UserSelectors.userById(state, authUser?.id));
  const [queryParams, setQueryParams] = useQueryParams();

  const detectedMarket = useSelector((state: RootState) => DetectMarketSelectors.detectMarketState(state));

  useEffect(() => {
    const primaryMarketSet = sessionStorage.getItem('marketInitiated') || false;
    if (sessionUser?.primary_market && !primaryMarketSet) {
      sessionStorage.setItem('marketInitiated', String(true));
      const marketSlug = sessionUser.primary_market?.slug;
      if (marketSlug) dispatch(DetectMarketActions.selectMarket(marketSlug));
    }
  }, [dispatch, sessionUser?.primary_market]);

  const handleToggle = (newMarket: any) => {
    dispatch(DetectMarketActions.selectMarket(newMarket.slug));

    setQueryParams({
      ...queryParams,
      ...{ market_slug: newMarket.slug },
    });
  };

  const getMarket = () => {
    if (market) return market;
    if (Array.isArray(detectedMarket?.result) && detectedMarket?.result.length > 0) {
      const closestMarket = [...detectedMarket.result].sort((a: any, b: any) => a.distance - b.distance)[0];
      dispatch(DetectMarketActions.selectMarket(closestMarket.slug));
      return closestMarket.slug;
    }
    return '';
  };

  return <SwitchListMarket onClick={handleToggle} markets={marketsState.entities} currentMarket={getMarket()} />;
};

export default SwitchMarket;
