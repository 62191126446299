import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import { HeaderMenu } from './HeaderItems';

interface MobileMenuProps {
  children: React.ReactNode;
}

const useStyles = makeStyles()(() => ({
  root: {
    padding: 0,
  },
  menuIcon: {
    fontSize: '2rem',
  },
}));

const MobileMenu: React.FC<MobileMenuProps> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { classes } = useStyles();

  const handleOpenClick = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleOpenClick} classes={classes} size="large">
        <MenuIcon color="primary" className={classes.menuIcon} />
      </IconButton>
      <HeaderMenu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        {children}
      </HeaderMenu>
    </>
  );
};

export default MobileMenu;
