import React from 'react';
import { Menu, MenuProps } from '@mui/material';

const HeaderMenu: React.FC<MenuProps> = (props) => (
  // TODO: getContentAnchorEl={null}
  <Menu
    {...props}
    keepMounted
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  />
);

export default HeaderMenu;
