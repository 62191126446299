import React, { memo } from 'react';
import { AppBar, Theme, Toolbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface HeaderProps {
  left: React.ReactNode;
  navigation: React.ReactNode;
  avatar: React.ReactNode;
}

const useStyles = makeStyles()((theme: Theme) => ({
  navigation: {
    flexGrow: 1,
    margin: theme.spacing(0, 1),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
}));

const Header: React.FC<HeaderProps> = (props) => {
  const { left, navigation, avatar } = props;
  const { classes } = useStyles();

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar>
        <div>{left}</div>
        <div className={classes.navigation}>{navigation}</div>
        <div>{avatar}</div>
      </Toolbar>
    </AppBar>
  );
};

export default memo(Header);
