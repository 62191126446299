import React from 'react';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';

import { useTranslation } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import { useRouter } from 'next/router';
import OrganizationItem from './OrganizationItem';
import Button from '../../Button';
import { routes } from '../../../../utils/routing';

interface OrganizationsListProps {
  entities: any[];
  onClose: () => void;
  count: number;
}

const useStyles = makeStyles()((theme) => ({
  infoContainer: {
    padding: '0 !important',
  },
  organizationsInfo: {
    padding: '12px 0 !important',
  },
  itemsWrapper: {
    padding: '12px 0 !important',
  },
  items: {
    padding: 0,
    alignItems: 'stretch',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    gap: '16px 76px',
  },
  item: {
    display: 'flex',
    alignSelf: 'stretch',
    padding: '0 !important',
    width: 'calc(50% - 38px)',
    maxWidth: '50%',
    flexBasis: 'unset',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: 520,
    },
  },
  total: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
}));

const OrganizationsList: React.FC<OrganizationsListProps> = (props) => {
  const { entities, onClose, count } = props;
  const [t] = useTranslation('public');
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const isLessSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'), { noSsr: true });
  const { classes } = useStyles();
  const router = useRouter();

  const onViewMoreClick = () => {
    onClose();
    const route = routes.organizations();
    router.push(route.href);
  };

  const itemsCount = () => (isLessSm ? 3 : 4);

  return (
    !!entities?.length && (
      <Grid container spacing={3}>
        <Grid item className={classes.infoContainer} xs={12}>
          <Grid container className={classes.organizationsInfo} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.total} color="textSecondary" variant="body2">
                {count === 1
                  ? `${count} ${t('searchHeader.organizations.title')}`
                  : `${count} ${t('searchHeader.organizations.titlePlural')}`}
              </Typography>
            </Grid>
            {!isMobile && count > 0 && (
              <Grid item>
                <Button rounded color="primary" size="small" variant="outlined" href="" onClick={onViewMoreClick}>
                  {t('searchHeader.organizations.viewMoreResults').toUpperCase()}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.itemsWrapper} xs={12}>
          <Grid className={classes.items} container xs={12}>
            {entities.slice(0, itemsCount()).map((organization) => (
              <Grid className={classes.item} key={organization.id} item xs={12} sm={12} md={5} lg={5}>
                <OrganizationItem organization={organization} onRedirect={onClose} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        {isMobile && count > 0 && (
          <Grid item xs>
            <Grid container justifyContent="center">
              <Grid item>
                <Button rounded color="primary" size="small" variant="outlined" href="" onClick={onViewMoreClick}>
                  {t('searchHeader.organizations.viewMoreResults').toUpperCase()}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  );
};

export default OrganizationsList;
