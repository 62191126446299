import React, { useMemo, useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteProps as MuiAutocompleteProps,
  TextField,
  TextFieldProps,
  UseAutocompleteProps,
} from '@mui/material';

export type Option = {
  hours: string;
  minutes: string;
  period: string;
};

type AutocompleteProps = MuiAutocompleteProps<Option, boolean | undefined, boolean | undefined, boolean | undefined> &
  UseAutocompleteProps<Option, boolean | undefined, boolean | undefined, boolean | undefined>;

export type AutocompleteTimePickerProps = Omit<
  AutocompleteProps,
  | 'value'
  | 'inputValue'
  | 'defaultValue'
  | 'options'
  | 'multiple'
  | 'onChange'
  | 'onInputChange'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'renderInput'
> & {
  hoursStep?: number;
  minutesStep?: number;
  value: Option;
  TextFieldProps?: TextFieldProps;
  onChange: (value: Option) => void;
};

const hoursRegex = '(\\d{1,2})';
const separatorRegex = '\\D';
const minutesRegex = '(\\d{1,2})';
const requiredRegex = new RegExp(`^${hoursRegex}${separatorRegex}${minutesRegex}\\W*([aApP][mM])`);
const optionalRegex = new RegExp(`^${hoursRegex}${separatorRegex}?${minutesRegex}?\\W*([aApP]?[mM]?)`);
const maxHours = 12;
const minHours = 1;
const maxMinutes = 55;
const minMinutes = 0;
const separator = ':';
const periods = ['AM', 'PM'];

const matchOption = (value: string, regex: RegExp): Option => {
  if (value) {
    const matched = value.match(regex);
    if (matched) {
      return {
        hours: matched[1],
        minutes: matched[2],
        period: matched[3].toUpperCase(),
      };
    }
  }
};

const formatMinutes = (minutes: number | string) => `0${minutes}`.slice(-2);

const formatOption = ({ hours, minutes, period }: Option) => `${hours}${separator}${formatMinutes(minutes)} ${period}`;

const filterOptions: AutocompleteProps['filterOptions'] = (options, state) => {
  const matchedOption = matchOption(state.inputValue, optionalRegex);
  if (!matchedOption) return options;

  return options.filter(
    (option) =>
      option.hours.startsWith(matchedOption.hours) &&
      (!matchedOption.minutes || option.minutes.startsWith(matchedOption.minutes)) &&
      (!matchedOption.period || option.period.startsWith(matchedOption.period)),
  );
};

const getOptionLabel: AutocompleteProps['getOptionLabel'] = (option) => formatOption(option as Option);

const getInputValue = (inputValue: string, value: Option) => {
  if (typeof inputValue === 'string') return inputValue;
  if (value) return formatOption(value);
  return '';
};

const AutocompleteTimePicker: React.FC<AutocompleteTimePickerProps> = (props) => {
  const {
    value,
    hoursStep = 1,
    minutesStep = 5,
    freeSolo = true,
    disableClearable = true,
    TextFieldProps,
    onChange,
    onBlur,
    ...rest
  } = props;

  const [inputValue, setInputValue] = useState<string>(null);
  const options = useMemo(() => {
    const options: Option[] = [];
    periods.forEach((period) => {
      for (let hours = minHours; hours <= maxHours; hours += hoursStep) {
        for (let minutes = minMinutes; minutes <= maxMinutes; minutes += minutesStep) {
          options.push({
            hours: String(hours),
            minutes: formatMinutes(minutes),
            period,
          });
        }
      }
    });

    return options;
  }, [hoursStep, minutesStep]);

  const handleChange: AutocompleteProps['onChange'] = (event, value, reason) => {
    if (reason === ('selectOption' as AutocompleteChangeReason)) {
      onChange(value as Option);
    } else if (reason === ('clear' as AutocompleteChangeReason)) {
      onChange(null);
    } else if (reason === ('createOption' as AutocompleteChangeReason)) {
      if (typeof value === 'string') {
        const matchedOption = matchOption(inputValue, requiredRegex);
        if (matchedOption) {
          onChange(matchedOption);
        }
      } else {
        onChange(value as Option);
      }
    }
  };

  const handleInputChange: AutocompleteProps['onInputChange'] = (event, inputValue, reason) => {
    if (reason === 'clear') {
      setInputValue('');
    } else if (reason === 'reset') {
      setInputValue(null);
    } else if (reason === 'input') {
      setInputValue(inputValue);

      const matchedOption = matchOption(inputValue, requiredRegex);
      if (matchedOption) {
        onChange(matchedOption);
      }
    }
  };

  const handleBlur: AutocompleteProps['onBlur'] = (e) => {
    setInputValue(null);

    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <Autocomplete
      {...rest}
      freeSolo={freeSolo}
      disableClearable={disableClearable}
      options={options}
      inputValue={getInputValue(inputValue, value)}
      onBlur={handleBlur}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...TextFieldProps}
          {...params}
          InputLabelProps={{
            ...TextFieldProps.InputLabelProps,
            ...params.InputLabelProps,
            shrink: true,
          }}
          sx={{
            '& legend': { display: 'none' },
            '& .MuiOutlinedInput-notchedOutline': {
              top: '0 !important',
            },
          }}
        />
      )}
    />
  );
};

export default AutocompleteTimePicker;
